<template>
  <div class="auth-wrapper auth-v2">
    <b-spinner style="width: 50px; height: 50px" class="spinner"  v-if="isSpinner"/>
    <b-row class="auth-inner m-0" v-if="!isSpinner">

      <!-- Brand logo-->
     <b-link
          class="brand-logo"
          to="/"
        >
          <span class="brand-logo">
            <b-img
              src="@/assets/images/newLogo.png"
              style="filter: invert(1);height: 100%;width: 166px;"
              alt="logo"
            />
          </span>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        style="background: #000000;"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Forgot Password? 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Enter your email and we'll send you instructions to reset your password
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                label="Email"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
              >
                Send reset link
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> Back to login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from "axios";
import firebase from "@/utils/firebaseInit";
const db = firebase.firestore()
import {dbCollections} from "@/utils/firebaseCollections";

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
      isSpinner:false,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          var self = this;
          self.isSpinner = true;
           db.collection(dbCollections.USERS).where("email","==",self.userEmail).get().then((resp)=>{
              if (resp.empty) {
                self.$toast.error("No such user found for entered email.");
                self.isSpinner = false;
              } else {
              console.log("STEP 1:",resp.docs[0].data());
              if (resp.docs[0].data().userRole && resp.docs[0].data().userRole == "admin") {
                axios.post(process.env.VUE_APP_APIURL + '/api/v1/sendForgotPasswordEmail', {
                // axios.post("http://localhost:4000" + '/api/v1/sendForgotPasswordEmail', {
                            uid: resp.docs[0].data().id,
                            email: self.userEmail,
                            isAdmin: true
                }).then((apiRes) => {
                  console.log("STEP 2:",apiRes); 
                    if(apiRes.data.status){
                      self.isSpinner = false;
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Email Send Succesfully.',
                          icon: 'EditIcon',
                          variant: 'success',
                        },
                      })
                        // this.$toast.success("Email send successfully.")
                        self.$router.push("/login");
                    } else {
                        self.isSpinner = false;
                        console.error(apiRes.data.statusText);
                    }
                })
                .catch((error) =>{
                    self.isSpinner = false;
                    console.error(error);
                })
              } else {
                self.userEmail = "";
                this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Admin Not Found.',
                      icon: 'EditIcon',
                      variant: 'danger',
                    },
                })
                self.isSpinner = false;
              }
              // axios.post(process.env.VUE_APP_APIURL + '/api/v1/sendForgotPasswordEmail', {
          }
        })
        } else {
          this.isSpinner = false;
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.spinner {
  z-index: 999;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}
</style>
